import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { printServicesSelector } from "~/redux/state/printServices"
import { RootState } from "~/redux/store"

export const usePrintServices = (ids: EntityId[]) => {
  const printServices = useSelector((state: RootState) =>
    printServicesSelector.selectByIds(state, ids)
  )

  return {
    printServices,
  }
}
