import { Button, useDisclosure } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Order } from "~/interfaces/entities/autopilot/Order"
import { actions } from "~/redux/process"
import { useCanReOrder } from "../hooks/useCanReOrder"
import { usePlaceReOrder } from "../hooks/usePlaceReOrder"
import ReOrderProductCompatibilityDialog from "./ReOrderProductCompatibilityDialog"

interface Props {
  order: Order
}

const ReOrderButton: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isOpen, onOpen: openDialog, onClose: closeDialog } = useDisclosure()
  const [canReorderSomeProducts, setCanReorderSomeProducts] = useState(true)
  const [lineItemLabels, setLineItemLabels] = useState<string[]>([])
  const { canReOrder } = useCanReOrder(order)

  const onOpen = useCallback(
    ({
      canReOrderSomeProducts,
      lineItemLabels,
    }: {
      canReOrderSomeProducts: boolean
      lineItemLabels: string[]
    }) => {
      setCanReorderSomeProducts(canReOrderSomeProducts)
      setLineItemLabels(lineItemLabels)
      openDialog()
    },
    [openDialog]
  )

  const onClose = useCallback(() => {
    setCanReorderSomeProducts(true)
    setLineItemLabels([])
    closeDialog()
  }, [closeDialog])

  const { placeReOrder } = usePlaceReOrder({ order, onOpen, onClose })

  const onDialogDecision = useCallback(
    choice => {
      dispatch(actions.productCompatibilityDialog({ choice }))
      closeDialog()
    },
    [closeDialog, dispatch]
  )

  return (
    <>
      <Button
        id={`reorder-${order.id}`}
        colorScheme="primary"
        color="primary.text"
        isDisabled={!canReOrder}
        onClick={placeReOrder}
      >
        {t("components.account.orders.preview.ReOrderButton")}
      </Button>
      <ReOrderProductCompatibilityDialog
        isOpen={isOpen}
        onClose={() => onDialogDecision(false)}
        onConfirm={() => onDialogDecision(true)}
        canReOrderSomeProducts={canReorderSomeProducts}
        lineItemLabels={lineItemLabels}
      />
    </>
  )
}

export default ReOrderButton
