import { Box, Button, Icon, Stack, useTheme } from "@chakra-ui/react"
import { Link } from "gatsby"
import { trim } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { BiHelpCircle } from "react-icons/bi"
import { FaBox, FaUserCircle } from "react-icons/fa"
import { IoMdLock } from "react-icons/io"
import { useLocation } from "react-use"
import { useApplicationState } from "~/hooks/useApplicationState"
import { usePage } from "~/hooks/usePage"
import { useSettings } from "~/hooks/useSettings"

interface Props {}

const AccountAside: React.FC<Props> = () => {
  const { t } = useTranslation()
  return (
    <Stack maxWidth="100%" spacing={{ base: 2, lg: 8 }}>
      <Stack
        pr={{ base: 0, lg: 5 }}
        borderRightWidth={{ base: 0, lg: 2 }}
        borderColor="gray.200"
        borderStyle="solid"
        spacing={{ base: 3, xl: 4 }}
      >
        <AccountAsideItem
          id="account"
          link="/account"
          title={t("templates.layout.account.AccountAside.Account")}
          icon={FaUserCircle}
        />
        <AccountAsideItem
          id="orders"
          link="/account/orders"
          title={t("templates.layout.account.AccountAside.Orders")}
          icon={FaBox}
        />
        <AccountAsideItem
          id="change-password"
          link="/account/change-password"
          title={t("templates.layout.account.AccountAside.ChangePassword")}
          icon={IoMdLock}
        />
        <NeedHelpLink />
      </Stack>
    </Stack>
  )
}

export default AccountAside

const AccountAsideItem = ({
  id,
  link,
  title,
  icon,
  variant = "ghost",
}: {
  id?: string
  link: string
  title: string
  icon: any
  variant?: string
}) => {
  const {
    colors: { primary },
  } = useTheme()
  const { href } = useLocation()
  const { baseUrl } = useApplicationState()
  const isActive = trim(href, "/") === `${baseUrl}${trim(link, "/")}`

  return isActive ? (
    <Button
      id={id}
      bg="primary.50"
      borderColor="primary.400"
      colorScheme="primary"
      variant={variant}
      leftIcon={<Icon as={icon} boxSize={5} mr={1} />}
      width={variant === "link" ? "auto" : "100%"}
      justifyContent="left"
      ml={variant === "link" ? 4 : 0}
      borderStyle="solid"
      borderWidth={2}
    >
      {title}
    </Button>
  ) : (
    <Button
      id={id}
      colorScheme="primary"
      variant={variant}
      as={Link}
      leftIcon={<Icon as={icon} boxSize={5} mr={1} />}
      to={link}
      width={variant === "link" ? "auto" : "100%"}
      justifyContent="left"
      ml={variant === "link" ? 4 : 0}
      borderStyle="solid"
      borderWidth={2}
      borderColor="transparent"
      activeStyle={{
        background: primary[50],
        borderColor: primary[400],
      }}
    >
      {title}
    </Button>
  )
}

const NeedHelpLink = () => {
  const {
    settings: { helpPageId, helpPageLabel },
  } = useSettings()
  const { t } = useTranslation()
  const { page } = usePage(helpPageId as number)

  if (!helpPageId) {
    return null
  }

  return (
    <Box>
      <AccountAsideItem
        id="help-link"
        link={`/${page!.slug}`!}
        title={
          helpPageLabel
            ? helpPageLabel
            : t("templates.layout.account.AccountAside.HelpPageLabel.Default")
        }
        icon={BiHelpCircle}
        variant="link"
      />
    </Box>
  )
}
