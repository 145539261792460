import { usePage } from "~/hooks/usePage"
import { usePrintServices } from "~/hooks/usePrintServices"
import { Order } from "~/interfaces/entities/autopilot/Order"

export const useCanReOrder = (order: Order) => {
  const pageId = order.metaData?.web?.pageId ?? 0

  const { isReorderable } = order
  const { page } = usePage(pageId)

  const remotePrintServiceId = Number(order.printService[0].id)

  const printServiceIds = page
    ? [...page.printServices.delivery, ...page.printServices.pickup].map(
        p => p.id
      )
    : []

  const { printServices } = usePrintServices(printServiceIds)
  const foundRequiredPrintService = printServices.find(
    printService => printService?.remoteId === remotePrintServiceId
  )

  return {
    canReOrder: Boolean(page && foundRequiredPrintService && isReorderable),
  }
}
