import {
  Flex,
  Heading,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useSettings } from "~/hooks/useSettings"
import { Order } from "~/interfaces/entities/autopilot/Order"
import { formatDate } from "~/services/Utils"
import { OrderHeader } from "../OrderHeader"
import OrderStatus from "../OrderStatus"
import AddressDetails from "./OrderPreviewFulfillment"
import ReOrderButton from "./ReOrderButton"

interface Props {
  order?: Order
  isLoading: boolean
}

const OrderPreviewHeading: React.FC<Props> = ({ order, isLoading }) => {
  const { t } = useTranslation()
  const {
    settings: { isReorderEnabled },
  } = useSettings()
  const { language } = useApplicationState()
  return (
    <Flex
      mt={8}
      alignItems="start"
      justifyContent="start"
      flexDir={{ base: "column", sm: "row" }}
    >
      <Stack
        spacing={5}
        width={{ base: "100%", sm: "50%" }}
        mb={5}
        pr={{ base: 0, sm: 30, md: 100 }}
      >
        <OrderPreviewBlock
          title={t(
            "components.account.orders.preview.OrderPreviewHeading.OrderNumber"
          )}
          isLoading={isLoading}
        >
          {!isLoading && (
            <Stack spacing={1}>
              {Boolean(order?.displayOrderIds) && (
                <OrderHeader order={order!} />
              )}
              <OrderStatus status={order!.displayStatus} />
            </Stack>
          )}
        </OrderPreviewBlock>

        <OrderPreviewBlock
          title={t(
            "components.account.orders.preview.OrderPreviewHeading.Date"
          )}
          isLoading={isLoading}
        >
          {!isLoading && (
            <Text>
              {formatDate(new Date(order!.createdAt), "PP", language)}
            </Text>
          )}
        </OrderPreviewBlock>

        {isReorderEnabled && !isLoading && <ReOrderButton order={order!} />}
      </Stack>

      <Flex width={{ base: "100%", sm: "50%" }}>
        <OrderAddress order={order} isLoading={isLoading} />
      </Flex>
    </Flex>
  )
}

export default OrderPreviewHeading

interface OrderPreviewBlockProps {
  title: string
  isLoading: boolean
  children: any
}

const OrderPreviewBlock: React.FC<OrderPreviewBlockProps> = ({
  title,
  isLoading = true,
  children,
}) => {
  return (
    <Stack spacing={3}>
      <Heading as="h3" size="md">
        {title}
      </Heading>

      {!isLoading ? (
        children
      ) : (
        <SkeletonText startColor="gray.50" endColor="gray.100" noOfLines={1} />
      )}
    </Stack>
  )
}

const OrderAddress = ({
  order,
  isLoading,
}: {
  order?: Order
  isLoading: boolean
}) => {
  const { t } = useTranslation()
  const title =
    order?.fulfillmentType === "pickup"
      ? t(
          "components.account.orders.preview.OrderPreviewHeading.OrderAddress.PickUp"
        )
      : t(
          "components.account.orders.preview.OrderPreviewHeading.OrderAddress.Delivery"
        )

  return (
    <Stack spacing={3} w="100%">
      {!isLoading ? (
        <Heading as="h3" size="md">
          {title!}
        </Heading>
      ) : (
        <Skeleton
          height="30px"
          startColor="gray.50"
          endColor="gray.100"
          w="100%"
        />
      )}

      <AddressDetails order={order} isLoading={isLoading} />
    </Stack>
  )
}
