import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import OrderGroupedLineItems from "~/components/account/orders/preview/grouped-line-items/OrderGroupedLineItems"
import OrderGroupedLineItemsMobile from "~/components/account/orders/preview/grouped-line-items/OrderGroupedLineItemsMobile"
import OrderPreviewHeading from "~/components/account/orders/preview/OrderPreviewHeading"
import OrderPreviewSummary from "~/components/account/orders/preview/OrderPreviewSummary"
import ReOrderWarning from "~/components/account/orders/preview/ReOrderWarning"
import { useAccountOrder } from "~/hooks/useAccountOrder"
import AccountLayout from "~/templates/layout/account/AccountLayout"

interface Props {
  params: {
    id: string
  }
}

const Order: React.FC<Props> = ({ params }) => {
  const { id } = params
  const { order, isLoading } = useAccountOrder(id)
  const { t } = useTranslation()
  const isDesktop = useBreakpointValue({
    base: false,
    md: true,
  })

  return (
    <AccountLayout
      title={t("pages.account.orders.id.OrderSummary")}
      isLoading={isLoading}
      width={{ base: "100%", lg: "75%", xl: "65%" }}
    >
      <Flex flexDir="row" alignItems="center">
        <Box
          textDecoration="underline"
          _hover={{
            textDecoration: "none",
          }}
        >
          <Link to="/account/orders">
            {t("pages.account.orders.id.MyOrders")}
          </Link>
        </Box>
        <Text mx={1} size="lg">
          &gt;
        </Text>
        <Text color="gray.400">#{id}</Text>
      </Flex>
      <OrderPreviewHeading order={order} isLoading={isLoading} />
      {!isLoading && <ReOrderWarning order={order!} />}
      {isDesktop ? (
        <OrderGroupedLineItems order={order} isLoading={isLoading} />
      ) : (
        <OrderGroupedLineItemsMobile order={order} isLoading={isLoading} />
      )}
      <Flex width="100%" justifyContent="flex-end">
        <Box width={{ base: "100%", lg: "60%" }} mt={8}>
          <OrderPreviewSummary order={order!} isLoading={isLoading} />
        </Box>
      </Flex>
    </AccountLayout>
  )
}

export default Order
