import { Skeleton, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import { groupBy, values } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { Order } from "~/interfaces/entities/autopilot/Order"
import OrderLineItems from "../line-items/OrderLineItems"

interface Props {
  order?: Order
  isLoading: boolean
}

const OrderGroupedLineItems: React.FC<Props> = ({ order, isLoading }) => {
  const { t } = useTranslation()
  if (isLoading) {
    return <LineItemsSkeleton />
  }

  const groupedLineItems = values(groupBy(order?.lineItems, "product.[0].id"))
  const totalGroups = groupedLineItems.length

  return (
    <Table variant="unstyled" size="md" my={3}>
      <Thead>
        <Tr>
          <Th>
            {t(
              "components.account.orders.preview.grouped-line-items.OrderGroupedLineItems.Thumbnail"
            )}
          </Th>
          <Th>
            {t(
              "components.account.orders.preview.grouped-line-items.OrderGroupedLineItems.Item"
            )}
          </Th>
          <Th textAlign="right">
            {t(
              "components.account.orders.preview.grouped-line-items.OrderGroupedLineItems.Price"
            )}
          </Th>
          <Th aria-hidden={true} px={2}></Th>
        </Tr>
      </Thead>

      <Tbody>
        {groupedLineItems.map((lineItems, index) => {
          return (
            <OrderLineItems
              key={index}
              lineItems={lineItems}
              currency={order!.currency}
              totalGroups={totalGroups!}
              index={index}
            />
          )
        })}
      </Tbody>
    </Table>
  )
}

export default OrderGroupedLineItems

export const LineItemsSkeleton = () => {
  return (
    <Skeleton
      mt={4}
      height="70px"
      startColor="gray.50"
      endColor="gray.100"
      w="100%"
    />
  )
}
