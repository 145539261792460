import { Alert, AlertIcon } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { Order } from "~/interfaces/entities/autopilot/Order"
import { useCanReOrder } from "../hooks/useCanReOrder"

interface Props {
  order: Order
}

const ReOrderWarning: React.FC<Props> = ({ order }) => {
  const { canReOrder } = useCanReOrder(order)
  const { t } = useTranslation()

  if (canReOrder) {
    return null
  }

  return (
    <Alert status="warning" borderRadius="md">
      <AlertIcon />
      {t("components.account.orders.preview.ReOrderWarning")}
    </Alert>
  )
}

export default ReOrderWarning
