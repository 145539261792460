import { Box, Heading, HStack, Progress, Text } from "@chakra-ui/react"
import React from "react"
import AccountGate from "~/components/account/AccountGate"
import AuthGate from "~/components/account/AuthGate"
import AccountAside from "./AccountAside"

interface Props {
  title?: string
  description?: string
  children: any
  isLoading?: boolean
  fullWidth?: boolean
  width?: any
}

const AccountLayout: React.FC<Props> = ({
  title,
  description,
  children,
  isLoading = false,
  fullWidth = true,
  width,
}) => {
  const mainBoxWidth = fullWidth ? "100%" : { base: "100%", xl: "50%" }

  return (
    <AccountGate>
      <AuthGate>
        <HStack
          alignItems="stretch"
          spacing={{ base: 0, xl: 5 }}
          flexDirection={["column", "column", "column", "row"]}
        >
          <AccountAside />
          <Box w={width ?? mainBoxWidth}>
            <Box mt={{ base: 5, lg: 0 }} px={{ base: 5, xl: 0 }}>
              {isLoading && <Progress size="xs" isIndeterminate mb={5} />}
              {title && (
                <Heading as="h1" size="lg" mb={3}>
                  {title}
                </Heading>
              )}
              <Text>{description}</Text>
              {children}
            </Box>
          </Box>
        </HStack>
      </AuthGate>
    </AccountGate>
  )
}

export default AccountLayout
