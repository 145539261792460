import { useDispatch } from "react-redux"
import { actions, PlaceReOrderPayload } from "~/redux/process"

export const usePlaceReOrder = (payload: PlaceReOrderPayload) => {
  const dispatch = useDispatch()

  const placeReOrder = () =>
    dispatch(
      actions.placeReOrder({
        ...payload,
      })
    )

  return { placeReOrder }
}
