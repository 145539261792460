import { useEffect, useRef } from "react"
import { PrinticularApi } from "~/services/PrinticularApi"
import { createPrinticularApi } from "~/services/PrinticularApiProxy"
import { useApplicationState } from "./useApplicationState"
import { useAuth } from "./useAuth"
import { useSettings } from "./useSettings"

export const usePrinticularApi = () => {
  const settings = useSettings()
  const {
    state: { isAuthenticated, loginToken },
  } = useAuth()

  const { language } = useApplicationState()

  const {
    settings: { remoteApiUrl, remoteApiClientApiKey, deviceToken },
  } = settings

  let authToken = remoteApiClientApiKey
  if (isAuthenticated) {
    authToken = loginToken
  }

  const savedApi = useRef<PrinticularApi>(
    createPrinticularApi(remoteApiUrl, authToken, deviceToken, language)
  )

  useEffect(() => {
    savedApi.current.setDeviceToken(deviceToken)
    savedApi.current.setAccessToken(authToken)
  }, [authToken, deviceToken])

  return savedApi.current
}
