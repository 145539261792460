import { ListItem, UnorderedList } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import ConfirmationModal from "~/components/ConfirmationModal"
import { useApplicationState } from "~/hooks/useApplicationState"
import { t as translateDialog } from "i18next"

const someProductsAreMissingText = translateDialog(
  "components.account.orders.preview.ReOrderProductCompatibilityDialog.SomeMissingText"
)
const allProductsAreMissingText = translateDialog(
  "components.account.orders.preview.ReOrderProductCompatibilityDialog.AllMissingText"
)

const allProductsAreMissingIntTitle = translateDialog(
  "components.account.orders.preview.ReOrderProductCompatibilityDialog.IntTitle"
)
const allProductsAreMissingUSTitle = translateDialog(
  "components.account.orders.preview.ReOrderProductCompatibilityDialog.USTitle"
)

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  canReOrderSomeProducts: boolean
  lineItemLabels: string[]
}

const ReOrderProductCompatibilityDialog: React.FC<Props> = ({
  onClose,
  onConfirm,
  isOpen,
  canReOrderSomeProducts,
  lineItemLabels,
}) => {
  const { t } = useTranslation()
  const { defaultLocale } = useApplicationState()

  let title = t(
    "components.account.orders.preview.ReOrderProductCompatibilityDialog.Title"
  )

  if (!canReOrderSomeProducts) {
    title =
      defaultLocale === "US"
        ? allProductsAreMissingUSTitle
        : allProductsAreMissingIntTitle
  }

  return (
    <ConfirmationModal
      id="product-compatibility"
      title={title}
      description={
        canReOrderSomeProducts
          ? someProductsAreMissingText
          : allProductsAreMissingText
      }
      onClose={onClose}
      onConfirm={onConfirm}
      isConfirmVisible={canReOrderSomeProducts}
      isOpen={isOpen}
    >
      {lineItemLabels.length > 0 && (
        <UnorderedList pl={6} mt={3}>
          {lineItemLabels.map(label => {
            return <ListItem key={label}>{label}</ListItem>
          })}
        </UnorderedList>
      )}
    </ConfirmationModal>
  )
}

export default ReOrderProductCompatibilityDialog
