import { navigate } from "gatsby-link"
import React from "react"
import { useSettings } from "~/hooks/useSettings"
import { isBrowser } from "~/services/Utils"

interface Props {
  children: any
}

const AccountGate: React.FC<Props> = ({ children }) => {
  const {
    settings: { isUserAccountEnabled },
  } = useSettings()

  if (isBrowser && !isUserAccountEnabled) {
    navigate("/")
    return null
  }

  return children
}

export default AccountGate
