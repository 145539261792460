import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { IoIosArrowDown } from "react-icons/io"
import { LineItem } from "~/interfaces/entities/autopilot/AccountWithOrders"
import { formatCurrency } from "~/services/Utils"
import OrderPreviewImages from "../../OrderPreviewImages"
import { ExpandedImagesArea } from "./OrderLineItems"

const OrderLineItemsMobile = ({
  lineItems,
  currency,
  totalGroups,
  index,
}: {
  lineItems: LineItem[]
  currency: string
  totalGroups: number
  index: number
}) => {
  const { t } = useTranslation()
  const { isOpen, onToggle } = useDisclosure()
  const isExtraSmallDevice = useBreakpointValue({
    base: true,
    sm: false,
  })

  const totalLineItems = lineItems.length
  const isLastItem = index + 1 === totalGroups

  const [firstLineItem] = lineItems
  const [product] = firstLineItem.product

  const { unitPrice } = lineItems.reduce(
    (lineItem, current) => {
      lineItem.unitPrice += Number(current.unitPrice)
      return lineItem
    },
    { unitPrice: 0 }
  )

  const lineItemsPreview = lineItems.slice(0, 3)
  const totalPreviews = lineItemsPreview.length

  const lineItemInfos = (
    <VStack alignItems="space-between" mb={{ base: 4, sm: 0 }}>
      <Flex>
        <Text fontWeight="bold" fontSize="md">
          {product.name}
        </Text>
      </Flex>

      <Flex
        w={{ base: "full", sm: "150px" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text>
          {t(
            "components.account.orders.preview.line-items.OrderLineItemsMobile.Quantity"
          )}
        </Text>
        <Text>{totalLineItems}</Text>
      </Flex>

      <Flex
        w={{ base: "full", sm: "150px" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text>
          {t(
            "components.account.orders.preview.line-items.OrderLineItemsMobile.Price"
          )}
        </Text>
        <Text>{formatCurrency(currency, unitPrice)}</Text>
      </Flex>
    </VStack>
  )

  return (
    <Box
      width="100%"
      boxShadow="base"
      borderRadius={5}
      overflow="hidden"
      mb={isLastItem ? 0 : 5}
    >
      <Box background="gray.100" p={4}>
        {isExtraSmallDevice && lineItemInfos}

        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          width="100%"
        >
          <VStack
            w={{ base: "100%", sm: "auto" }}
            pt={totalPreviews > 1 ? 4 : 0}
            px={0}
            alignItems={{ base: "center", sm: "flex-start" }}
            spacing={4}
          >
            <VStack width={{ base: "full", sm: "180px" }} spacing={4}>
              <OrderPreviewImages
                lineItems={lineItemsPreview}
                maxHeight="120px"
              />
              <Button
                id="view-images-mobile"
                variant="outline"
                colorScheme="secondary"
                size="sm"
                onClick={onToggle}
                mt={totalPreviews > 1 ? 6 : 1}
                mb={1}
                rightIcon={
                  <Icon
                    as={IoIosArrowDown}
                    boxSize={4}
                    transform={`rotate(${isOpen ? -180 : 0}deg)`}
                    transition="all ease-out .2s"
                  />
                }
              >
                {t(
                  "components.account.orders.preview.line-items.OrderLineItems.View"
                )}
              </Button>
            </VStack>
          </VStack>

          {!isExtraSmallDevice && (
            <Box w="100%" pl={4}>
              {lineItemInfos}
            </Box>
          )}
        </Flex>
      </Box>

      <Box>
        <ExpandedImagesArea isOpen={isOpen} lineItems={lineItems} />
      </Box>
    </Box>
  )
}

export default OrderLineItemsMobile
