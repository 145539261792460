import { Box } from "@chakra-ui/layout"
import { groupBy, values } from "lodash"
import React from "react"
import { Order } from "~/interfaces/entities/autopilot/Order"
import OrderLineItemsMobile from "../line-items/OrderLineItemsMobile"
import { LineItemsSkeleton } from "./OrderGroupedLineItems"

interface Props {
  order?: Order
  isLoading: boolean
}

const OrderGroupedLineItemsMobile: React.FC<Props> = ({ order, isLoading }) => {
  if (isLoading) {
    return <LineItemsSkeleton />
  }

  const groupedLineItems = values(groupBy(order?.lineItems, "product.[0].id"))
  const totalGroups = groupedLineItems.length

  return (
    <Box mt={5}>
      {groupedLineItems.map((lineItems, index) => {
        return (
          <OrderLineItemsMobile
            key={index}
            lineItems={lineItems}
            currency={order!.currency}
            totalGroups={totalGroups!}
            index={index}
          />
        )
      })}
    </Box>
  )
}

export default OrderGroupedLineItemsMobile
