import {
  Icon,
  Link as ChakraLink,
  SkeletonText,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { FaPhoneAlt } from "react-icons/fa"
import {
  Address,
  Store,
} from "~/interfaces/entities/autopilot/AccountWithOrders"
import { Order } from "~/interfaces/entities/autopilot/Order"
import { getCountryName } from "~/services/Utils"

interface Props {
  order?: Order
  isLoading: boolean
}

const AddressDetails: React.FC<Props> = ({ order, isLoading }) => {
  if (isLoading) {
    return (
      <SkeletonText
        startColor="gray.50"
        endColor="gray.100"
        noOfLines={5}
        w="100%"
      />
    )
  }

  const isPickup = order!.fulfillmentType === "pickup"

  return isPickup ? (
    <StoreAddress store={order!.store[0]} />
  ) : (
    <UserAddress address={order!.address[0]} />
  )
}

export default AddressDetails

const UserAddress = ({ address }: { address: Address }) => {
  return (
    <Stack alignItems="flex-start" spacing={1}>
      <Text>{address.name}</Text>
      <Text>{address.addressLine1}</Text>
      {address.addressLine2 && <Text>{address.addressLine2}</Text>}
      <Text>
        {address.postCode} {address.city}
      </Text>
      <Text>{getCountryName(address.country)}</Text>
      {address.phoneNumber && (
        <Text>
          <Icon as={FaPhoneAlt} boxSize={3.5} mr={2} />
          {address.phoneNumber}
        </Text>
      )}
    </Stack>
  )
}

const StoreAddress = ({ store }: { store: Store }) => {
  return (
    <Stack alignItems="flex-start" spacing={1}>
      <Text>{store.name}</Text>
      <Text>{store.storeAddress1}</Text>
      {store.storeAddress2 && <Text>{store.storeAddress2}</Text>}
      <Text>
        {store.storePostCode} {store.storeCity}
      </Text>
      <Text>{getCountryName(store.storeCountry)}</Text>

      {store.phone && (
        <ChakraLink
          href={`tel:${store.phone}`}
          color="gray.600"
          textDecoration="underline"
          display="flex"
          alignItems="center"
          _hover={{
            textDecoration: "none",
          }}
        >
          <Icon as={FaPhoneAlt} boxSize={3.5} mr={2} />
          {store.phone}
        </ChakraLink>
      )}
    </Stack>
  )
}
