import { useQuery } from "react-query"
import { useAuth } from "./useAuth"
import { usePrinticularApi } from "./usePrinticularApi"

export const useAccountOrder = (orderId: string) => {
  const {
    state: { isAuthenticated },
  } = useAuth()

  const api = usePrinticularApi()

  const {
    isLoading,
    isIdle,
    isError,
    data: order,
  } = useQuery(["order", orderId], () => api.getUserOrder(orderId), {
    enabled: isAuthenticated,
  })

  return { isLoading: isLoading || isIdle, isError, order }
}
