import { navigate } from "gatsby-link"
import React from "react"
import { useAuth } from "~/hooks/useAuth"
import { isBrowser } from "~/services/Utils"

interface Props {
  children: any
}

const AuthGate: React.FC<Props> = ({ children }) => {
  const {
    state: { isAuthenticating, isAuthenticated },
  } = useAuth()

  if (isBrowser && !isAuthenticating && !isAuthenticated) {
    navigate("/")
    return null
  }

  return children
}

export default AuthGate
